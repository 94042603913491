import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import localforage from 'localforage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

import api from './middleware/api';
import openTradeAllocationWindowMiddleware from './middleware/openTradeAllocationWindowMiddleware';
import setTradeAllocationWindowStateMiddleware from './middleware/setTradeAllocationWindowStateMiddleware';
import NopTransformer from './persistence/NopTransformer';
import AppState from './AppState';

const middlewares = [
  thunk,
  api,
  openTradeAllocationWindowMiddleware,
  setTradeAllocationWindowStateMiddleware,
];

const middleware = applyMiddleware(...middlewares);

const enhancer = composeWithDevTools(compose, middleware);

const persistConfig = {
  key: 'root',
  storage: localforage,
  whitelist: ['netOpenPosition'],
  transforms: [NopTransformer],
  stateReconciler: autoMergeLevel2,
  version: 1,
};

// @ts-ignore
const persistedReducer = persistReducer<AppState>(persistConfig, rootReducer());

const store = createStore(persistedReducer, enhancer);

persistStore(store);

type NopStore = typeof store;

export default store;

export type { NopStore };
