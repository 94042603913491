import { Product } from '@edfenergy/shift-desk-efa-calendar';
import { NopHorizon } from '../store/nop/reducer';
import productMapper from '../common/products/mapper';

export type NopData = {
  edf: number[];
  wbb: number[];
  batteries: number[];
  gasPeaker: number[];
};

export const blankNopData: NopData = {
  edf: [],
  wbb: [],
  batteries: [],
  gasPeaker: [],
};

type KeyedProducts = { [key: string]: Product };

const getNopDataForProducts = (
  horizon: NopHorizon,
  products: Product[],
): NopData => {
  const productsByPeriodId = products.reduce(
    (acc: KeyedProducts, product: Product): KeyedProducts => ({
      ...acc,
      [product.toId()]: product,
    }),
    {},
  );

  let nopData: NopData = { edf: [], wbb: [], batteries: [], gasPeaker: [] };

  Object.values(horizon).forEach((day) => {
    day.periods.forEach((period) => {
      if (!productsByPeriodId[period.period.periodId]) {
        return;
      }
      const edfNop = period.nop.filter((position) => position.name === 'EDF')[0]
        .position.nbp as number;
      const wbbNop = period.nop.filter((position) => position.name === 'WBB')[0]
        .position.nbp as number;
      const batteriesNop = period.nop
        .filter((position) => position.name === 'EDF')[0]
        .tier2.filter((tierTwo) => tierTwo.provider.name === 'Batteries NOP')[0]
        .position.nbp as number;
      const gasPeakerNop = period.nop
        .filter((position) => position.name === 'EDF')[0]
        .tier2.filter(
          (tierTwo) => tierTwo.provider.name === 'Gas Peaker NOP',
        )[0].position.nbp as number;
      nopData = {
        edf: [...nopData.edf, edfNop],
        wbb: [...nopData.wbb, wbbNop],
        batteries: [...nopData.batteries, batteriesNop],
        gasPeaker: [...nopData.gasPeaker, gasPeakerNop],
      };
    });
  });

  return nopData;
};

export default (horizon: NopHorizon, product: Product): NopData => {
  const halfHoursWithinProduct =
    productMapper().getHalfHourProductsContainedWithin(product);
  return getNopDataForProducts(horizon, halfHoursWithinProduct);
};
