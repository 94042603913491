const rowPriorities: { [key: string]: number } = {
  'Total NOP': 1,
  'EDF NOP': 2,
  'WBB NOP': 3,
  'Batteries NOP': 4,
  'Gas Peaker NOP': 5,
  'CONS NOP': 6,
  Batteries: 7,
  'Gas Peaker': 8,
  WBB: 9,
  Nuclear: 10,
  Coal: 11,
  Embed_Gen: 12,
  Customer_Vol: 13,
  Trades: 14,
  'WBB Trades': 15,
  'DA Hourly Auction - EPEX': 16,
  'DA Hourly Auction - N2EX': 17,
  'DA HH Auction - EPEX': 18,
  'DA HH Auction - N2EX': 19,
  'MIP - EPEX': 20,
  'MIP - N2EX': 21,
  'WAP - EPEX': 22,
  'WAP - N2EX': 23,
  'Cash Out': 24,
  IMRP: 25,
};
export default rowPriorities;

export type nopNames = 'Total' | 'EDF' | 'WBB' | 'Gas Peaker' | 'Batteries';
