import { Product, ProductType } from '@edfenergy/shift-desk-efa-calendar';
import { Action, Dispatch } from 'redux';
import productMapper from '../../common/products/mapper';

import store from '../index';
import {
  OpenTradeAllocationWindow,
  OPEN_TRADE_ALLOCATION_WINDOW,
  setTradeAllocationWindowState,
} from '../tradeAllocation/actions';
import {
  CounterpartySelect,
  ProductSelect,
  ProductSelectOption,
} from '../tradeAllocation/reducer';
import {
  queryPrivateTrades,
  setPrivateTradesAsLoading,
} from '../privateTrades/actions';
import AppState from '../AppState';
import { queryWaps, setWapsAsLoading } from '../waps/actions';
import suggestInternalAllocationVolumeAndSellDirection from '../../data/suggestInternalAllocationVolumeAndSellDirection';

interface MiddleWareAction extends Action {
  payload: OpenTradeAllocationWindow;
}

const productLabel = (product: Product): string => {
  let prefix = '';
  switch (product.getType()) {
    case ProductType.HalfHour:
      prefix = 'HH ';
      break;
    default:
      prefix = '';
      break;
  }

  return `${prefix}${product.getName()}`;
};

const getProductSelectOptions = (productId: string): ProductSelect => {
  const defaultProduct = Product.fromId(productId);

  const selected = {
    value: defaultProduct.toId(),
    label: productLabel(defaultProduct),
  };

  const mapper = productMapper();
  const options = [
    selected,
    ...mapper.getProductsThatStartAtSameTimeAs(defaultProduct).map(
      (product: Product): ProductSelectOption => ({
        value: product.toId(),
        label: productLabel(product),
      }),
    ),
  ];

  return {
    selected,
    options,
  };
};

const openTradeAllocationWindowMiddleware =
  () =>
  (next: Dispatch<MiddleWareAction>) =>
  async (action: MiddleWareAction) => {
    if (action.type !== OPEN_TRADE_ALLOCATION_WINDOW) {
      return next(action);
    }

    const { productId, selectedCounterparty } = action.payload;

    const { user, tradeAllocation } = store.getState() as AppState;
    if (
      productId === tradeAllocation.productId &&
      selectedCounterparty === tradeAllocation.counterpartyList.selected &&
      tradeAllocation.internalAllocationForm.volume.value !== ''
    ) {
      return next(action);
    }

    const traderId: string = user.traderID;

    const productSelectOptions = getProductSelectOptions(productId);

    const counterpartySelectOptions: CounterpartySelect = {
      selected: selectedCounterparty ?? {
        value: 'WBB',
        label: 'WBB',
      },
      options: [
        {
          value: 'WBB',
          label: 'WBB',
        },
        {
          value: 'Batteries',
          label: 'Batteries',
        },
        {
          value: 'Gas_Peaker',
          label: 'Gas Peaker',
        },
      ],
    };

    const productIds = productSelectOptions.options.map(
      (product) => product.value,
    );

    await store.dispatch(setWapsAsLoading());
    store.dispatch(queryWaps(productIds));

    await store.dispatch(setPrivateTradesAsLoading());
    store.dispatch(queryPrivateTrades(productIds));

    const { netOpenPosition } = store.getState() as AppState;

    const suggestedVolumeAndDirection =
      suggestInternalAllocationVolumeAndSellDirection(
        netOpenPosition.nop,
        Product.fromId(productId),
      );

    const volume = (suggestedVolumeAndDirection.volume ?? 0).toString();
    const { edfBuysOrSells } = suggestedVolumeAndDirection;

    return next(
      setTradeAllocationWindowState({
        isWindowOpen: true,
        isWindowExpanded: false,
        productId,
        productList: productSelectOptions,
        counterpartyList: counterpartySelectOptions,
        internalAllocationForm: {
          traderId: {
            initialValue: traderId,
            value: traderId,
          },
          edfBuysOrSells: {
            value: edfBuysOrSells,
            initialValue: edfBuysOrSells,
          },
          volume: {
            value: volume,
            initialValue: volume,
          },
          price: {
            initialValue: '',
            value: '',
          },
          wapSelection: {
            initialValue: null,
            value: null,
          },
          hasValidationTriggered: false,
        },
        pxAllocationForm: {
          ...tradeAllocation.pxAllocationForm,
          selectedTradeIds: [],
        },
      }),
    );
  };

export default openTradeAllocationWindowMiddleware;
