/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';

type DateDisplayProps = {
  settlementPeriodsInEFADay: string;
};

type ToolbarToastMessageProps = {
  isToday: boolean;
}

type ShowHideProps = {
  isFilterApplied: boolean;
}

type FilterListProps = {
  isMultiSelector: boolean;
  isFirstItem: boolean;
}

const IconButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  &:hover span > svg {
    fill: #188cfc;
  }
`;

const DateDisplayContainer = styled.div`
  background-color: #fff;
`;

const DateDisplayTable = styled.table`
  margin-left: 114px;
  width: 100%;
  table-layout: fixed;
  background-color: #fff;
`;

const DateDisplayRow = styled.tr<DateDisplayProps>`
  font-size: 12px;
  background-color: #fff;
  > td {
    color: transparent;
    width: 45px;
    background-color: #fff;
    pointer-events: none;
  }

  > td:nth-child(${({ settlementPeriodsInEFADay }) =>
    settlementPeriodsInEFADay}n
      + 1) {
    white-space: nowrap;
    left: 0px;
    color: #000;
    z-index: 999;
    position: sticky;
    border-left: 1px solid #d3d3d3;
    background-color: #fff;
  }

  > td > span {
    background-color: #fff;
    padding-right: 10px;
    padding-left: 8px;
  }
`;

const TableToolBar = styled.div<ToolbarToastMessageProps>`
  padding: 10px 0;
  display: flex;
  justify-content: left;
  background: #${({ isToday }) => isToday ? 'FFF' : 'db6c78'};
`;

const ToolBarItem = styled.div`
  z-index: 99;
  margin: auto 10px;
  font-size: 13px;
`;

const ToolbarMessage = styled.span`
  font-weight: bold;
  color: #333;
`

const ToolbarToastMessage = styled.span<ToolbarToastMessageProps>`
  padding: 5px;

  background: #${({ isToday }) => isToday ? '10367a' : 'dadada'};
  text-decoration: ${({ isToday }) => isToday ? 'none' : 'line-through'};
  color: #${({ isToday }) => isToday ? 'FFF' : '333'};
`

const TradeAllocationButton = styled.div`

  transition: color 0.5s, background-color 0.5s;

  &:hover {
    background-color: #1089FF;
    color: #FFFFFF;
    cursor: pointer;
  }

`;

const ShowHide = styled.span<ShowHideProps>`

  color: #${({ isFilterApplied, theme }) => isFilterApplied ? 'ff6000' : theme.colors.filter.internal.icon};
  cursor: pointer;

  &:hover {
    color: #${({ theme }) => theme.colors.filter.internal.hover};
  }

`;
const Form = styled.form``;

const FilterContainer = styled.div`

  background: #${({ theme }) => theme.colors.filter.internal.background};
  box-shadow: 0px 0px 0px rgba(16, 22, 26, 0.1), 0px 2px 4px rgba(16, 22, 26, 0.2), 0px 8px 24px rgba(16, 22, 26, 0.2);
  border-radius: 3px;
  border: 1px solid transparent;
  width: 200px;
  padding: 10px;
  position: absolute;

  margin-top: 10px;

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;

    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    
    border-bottom: 15px solid #${({ theme }) => theme.colors.filter.internal.background};

    top: -14px;

  }
`;

const FilterList = styled.div<FilterListProps>`
  clear: both;
  & label {
    display: flex;
    align-items: center;

    ${(props) => {
      const { isMultiSelector, isFirstItem } = props
      let boxShadow = '0px 0px 0px 0px white inset';
      let padding = '0px';
      let margin = '0px';
      if (isMultiSelector) {
        boxShadow = '0px -1px 0px -0.5px grey inset';
        padding = isFirstItem ? '0px 0px 3px 0px' : '3px 0px 3px 0px';
        margin = isFirstItem ? '0px 0px 3px 0px' : '3px 0px 3px 0px';
      }
      return css`
        box-shadow: ${boxShadow};
        padding: ${padding};
        margin: ${margin};
      `;
    }}
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  & span:last-of-type {
    padding-left: 12px;
  }
`;

export {
  IconButton,
  DateDisplayContainer,
  DateDisplayRow,
  DateDisplayTable,
  TableToolBar,
  ToolBarItem,
  ToolbarMessage,
  ToolbarToastMessage,
  TradeAllocationButton,
  ShowHide,
  Form,
  FilterContainer,
  FilterList,
  FilterWrapper,
};
