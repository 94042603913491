import styled from 'styled-components';

export const RedOverlayContainer = styled.div`
  background-color: rgb(255, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  pointer-events: none;
`;

export const OrangeOverlayContainer = styled.div`
  background-color: rgb(255, 100, 0, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  pointer-events: none;
`;
