import { DateTime } from 'luxon';
import _ from 'lodash';
import clock from '../../common/clock/clock';
import { Action } from '../actions';
import {
  TOGGLE_MANUAL_TRADING_ALLOWED_STATE,
  UpdateIndividualTradingAllowedStatePayload,
  UPDATE_INDIVIDUAL_TRADING_ALLOWED_STATE,
  UPDATE_TRADING_ALLOWED_STATE,
} from './actions';

export const toggleTradingAllowedState = (
  status: TradingAllowedStatus,
): TradingAllowedStatus =>
  status === 'OK_TO_TRADE' ? 'STOP_TRADING' : 'OK_TO_TRADE';

export type TradingAllowedStateApiResponse = {
  getTradingAllowedState: TradingAllowedStateData;
};

export type TradingAllowedStateData = TradingAllowedStateItem[];

export type TradingAllowedStatus = 'OK_TO_TRADE' | 'STOP_TRADING';

export type TradingAllowedTriggerType =
  | 'SPOTLIGHT'
  | 'MANUAL_TRADING_ALLOWED_TRIGGER';

export type TradingAllowedStateItem = {
  triggerType: TradingAllowedTriggerType;
  status: TradingAllowedStatus;
  updatedAt: string;
};

export type TradingAllowedState = {
  spotlight: IndividualTradingAllowedState;
  manualTradingAllowedTrigger: IndividualTradingAllowedState;
};

export type IndividualTradingAllowedState = {
  status: TradingAllowedStatus;
  updatedAt: string;
};

const initialState: TradingAllowedState = {
  spotlight: {
    status: 'OK_TO_TRADE',
    updatedAt: '1970-01-01T00:00:00',
  },
  manualTradingAllowedTrigger: {
    status: 'OK_TO_TRADE',
    updatedAt: '1970-01-01T00:00:00',
  },
};

const tradingAllowedState = (
  state: TradingAllowedState = initialState,
  action: Action,
): TradingAllowedState => {
  switch (action.type) {
    case UPDATE_TRADING_ALLOWED_STATE: {
      const { payload }: { payload: TradingAllowedStateData } = action;

      const apiResponseManualTradingAllowedState: TradingAllowedStateItem =
        payload.filter(
          (state) => state.triggerType === 'MANUAL_TRADING_ALLOWED_TRIGGER',
        )[0];

      const apiResponseSpotlightTradingAllowedState: TradingAllowedStateItem =
        payload.filter((state) => state.triggerType === 'SPOTLIGHT')[0];

      const newManualTradingAllowedState: IndividualTradingAllowedState =
        DateTime.fromISO(
          apiResponseManualTradingAllowedState.updatedAt,
        ).toMillis() >
        DateTime.fromISO(state.manualTradingAllowedTrigger.updatedAt).toMillis()
          ? _.omit(apiResponseManualTradingAllowedState, 'triggerType')
          : state.manualTradingAllowedTrigger;

      const newSpotlightTradingAllowedState: IndividualTradingAllowedState =
        DateTime.fromISO(
          apiResponseSpotlightTradingAllowedState.updatedAt,
        ).toMillis() > DateTime.fromISO(state.spotlight.updatedAt).toMillis()
          ? _.omit(apiResponseSpotlightTradingAllowedState, 'triggerType')
          : state.spotlight;

      return {
        spotlight: newSpotlightTradingAllowedState,
        manualTradingAllowedTrigger: newManualTradingAllowedState,
      };
    }
    case UPDATE_INDIVIDUAL_TRADING_ALLOWED_STATE: {
      const {
        payload,
      }: { payload: UpdateIndividualTradingAllowedStatePayload } = action;
      const triggerTypeAsStateKey = _.camelCase(
        payload.triggerType,
      ) as keyof TradingAllowedState;
      if (
        DateTime.fromISO(payload.state.updatedAt).toMillis() >
        DateTime.fromISO(state[triggerTypeAsStateKey].updatedAt).toMillis()
      )
        return {
          ...state,
          [triggerTypeAsStateKey]: payload.state,
        };
      return state;
    }
    case TOGGLE_MANUAL_TRADING_ALLOWED_STATE: {
      return {
        ...state,
        manualTradingAllowedTrigger: {
          status: toggleTradingAllowedState(
            state.manualTradingAllowedTrigger.status,
          ),
          updatedAt: clock().now().toISO(),
        },
      };
    }
    default:
      return state;
  }
};

export default tradingAllowedState;
