import gql from 'graphql-tag';

const nopQuery = gql(`
query getNop($limit: Int!, $token: String) {
  getNop(limit: $limit, token: $token) {
    changesets {
      id
      efaDate
      name
      tier
      parent
      periods {
        periodId
        nbp
        gate
        metadata {
          periodNumber
          startTime
          endTime
        }
      }
    }
    nextToken
  }
}
`);

const gateClosuresQuery = gql(`
  query {
    getCurrentGateClosures {
      currentGateDetails {
        date
        endTime
        period
        startTime
        time
      }
      marketGatePeriod {
        date
        endTime
        period
        startTime
      }
      pnGatePeriod {
        date
        endTime
        period
        startTime
      }
    }
  }
`);

const wapQuery = gql(`
  query getWapsForProducts($productIds: [ID!]!) {
    getWapsForProducts(productIds: $productIds) {
      productId
      publicWap
      privateBuyWap
      privateSellWap
    }
  }
`);

const privateTradesQuery = gql(`
  query getPrivateTrades($productIds: [ID!]!) {
    getPrivateTrades(productIds: $productIds) {
      productId
      buyOrSell
      price
      volume
      tradeExecutionTime
      tradeId
      allocationStatus
      orderId
    }
  }
`);

const tradingAllowedStateQuery = gql(`
  query getTradingAllowedState {
    getTradingAllowedState {
      status
      updatedAt
      triggerType
    }
  }
`);

const allocationSubmissionsQuery = gql(`
  query getAllocationSubmissions {
    getAllocationSubmissions {
      allocationSubmissionId
      xstring
      traderId
      application
      type
      tradeId
      counterparty
      buyOrSell
      volume
      price
      status
      productName
      createdAt
      updatedAt
      canResubmit
    }
  }
`);

export {
  nopQuery,
  gateClosuresQuery,
  wapQuery,
  privateTradesQuery,
  tradingAllowedStateQuery,
  allocationSubmissionsQuery,
};
