import gql from 'graphql-tag';

export const createInternalPowerTransfer = gql(`
mutation CreateInternalPowerTransfer($from: InternalTransferCounterparty
  !, $price: Float!, $productId: ID!, $to: InternalTransferCounterparty!, $trader: ID!, $volume: Float!) {
  createInternalPowerTransfer(input: {from: $from, price: $price, productId: $productId, to: $to, trader: $trader, volume: $volume}) {
    from
    price
    productId
    to
    trader
    volume
  }
}
`);

export const createInternalTradeAllocation = gql(`
mutation createInternalTradeAllocation($trader: ID!, $tradeIds: [ID!]!, $volumesToAllocate: [VolumeAllocationInput!]!) {
  createInternalTradeAllocation(input: {
    trader: $trader
    tradeIds: $tradeIds
    volumesToAllocate: $volumesToAllocate
  }) {
    trader
    tradeIds
    volumesToAllocate{
      counterparty
      volume
    }
  }
}
`);

export const createNotification = gql(`
mutation createNotification($dateTime: AWSDateTime!, $type: NotificationType!, $source: NotificationSource!) {
  createNotification(input: {
    message: ""
    dateTime: $dateTime
    type: $type
    source: $source
  }) {
    message
    dateTime
    type
    source
  }
}
`);

export const createAllocationResubmission = gql(`
mutation createAllocationResubmission($allocationSubmissionId: String!) {
  createAllocationResubmission(input: {
    allocationSubmissionId: $allocationSubmissionId
  }) {
    allocationSubmissionId
  }
}
`);
