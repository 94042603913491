import { logger } from '@edfenergy/shift-desk-monitoring';
import { Toggle } from '@edfenergy/shift-desk-wallace';
import React from 'react';
import { useDispatch } from 'react-redux';
import client from '../../auth/client';
import clock from '../../common/clock/clock';
import { createNotification } from '../../graphql/mutations';
import { toggleManualTradingAllowedState } from '../../store/tradingAllowedState/actions';
import {
  toggleTradingAllowedState,
  TradingAllowedStatus,
} from '../../store/tradingAllowedState/reducer';
import { useTradingAllowedState } from '../../store/tradingAllowedState/selector';

const mutateTradingAllowedState = async (status: TradingAllowedStatus) =>
  client
    .mutate({
      mutation: createNotification,
      variables: {
        dateTime: clock().now().toISO(),
        type: status,
        source: 'MANUAL_TRADING_ALLOWED_TRIGGER',
      },
    })
    .catch((error) => {
      logger.error('error', error);
    });

const ManualTradingAllowedTrigger: React.FC<{}> = () => {
  const dispatch = useDispatch();

  const tradingAllowedState = useTradingAllowedState();

  const handleOnToggleChange = () => {
    dispatch(toggleManualTradingAllowedState());
    mutateTradingAllowedState(
      toggleTradingAllowedState(
        tradingAllowedState.manualTradingAllowedTrigger.status,
      ),
    );
  };

  return (
    <Toggle
      size="sm"
      state={
        tradingAllowedState.manualTradingAllowedTrigger.status ===
        'STOP_TRADING'
      }
      name="manual_trading_allowed_toggle"
      id="manual_trading_allowed_toggle"
      onChange={handleOnToggleChange}
      text="STOP TRADING"
    />
  );
};

export default ManualTradingAllowedTrigger;
