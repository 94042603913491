import React from 'react';
import { RedOverlayContainer, OrangeOverlayContainer } from './style';

export type TriggerType = 'spotlight' | 'manual';

export type StopTradingOverlayProps = {
  triggerType: TriggerType;
};

const StopTradingOverlay: React.FC<StopTradingOverlayProps> = ({
  triggerType,
}) => {
  if (triggerType === 'spotlight') {
    return <RedOverlayContainer data-testid="stopTradingRedOverlay" />;
  }
  if (triggerType === 'manual') {
    return <OrangeOverlayContainer data-testid="stopTradingOrangeOverlay" />;
  }
  return null;
};

export default StopTradingOverlay;
