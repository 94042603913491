import AWSAppSyncClient from 'aws-appsync';
import { SubscriptionInterface } from './subscription';
import { notificationWasCreatedSubscription } from '../graphql/subscriptions';
import { NopStore } from '../store';
import {
  updateIndividualTradingAllowedState,
  UpdateIndividualTradingAllowedStatePayload,
} from '../store/tradingAllowedState/actions';

export type NotificationResponse = {
  data: {
    notificationWasCreated: {
      message: string;
      dateTime: string;
      type: 'OK_TO_TRADE' | 'STOP_TRADING';
      source: 'SPOTLIGHT' | 'MANUAL_TRADING_ALLOWED_TRIGGER';
    };
  };
};

class NotificationSubscription implements SubscriptionInterface {
  private observable: any;

  private subscription: any;

  private store: NopStore;

  constructor(client: AWSAppSyncClient<any>, store: NopStore) {
    this.store = store;
    this.observable = client.subscribe({
      query: notificationWasCreatedSubscription,
    });
  }

  start(): void {
    this.subscription = this.observable.subscribe({
      next: (response: NotificationResponse) => {
        const responsePayload = response.data.notificationWasCreated;
        const updateInput: UpdateIndividualTradingAllowedStatePayload = {
          state: {
            status: responsePayload.type,
            updatedAt: responsePayload.dateTime,
          },
          triggerType: responsePayload.source,
        };
        this.store.dispatch(updateIndividualTradingAllowedState(updateInput));
      },
      error: (error: any) => {
        console.log(
          `Error ocurred in subscription: NotificationSubscription`,
          error,
        );
        this.restart();
      },
    });
  }

  stop(): void {
    this.subscription.unsubscribe();
  }

  restart(): void {
    this.stop();
    this.start();
  }
}

export default NotificationSubscription;
