import { Product } from '@edfenergy/shift-desk-efa-calendar';
import { InternalTransferCounterparty } from '../components/TradeAllocationWindow/Tabs/InternalAllocation/submitInternalAllocation';
import { NopHorizon } from '../store/nop/reducer';
import getNopDataForProduct, { NopData } from './getNopDataForProduct';
import productMapper from '../common/products/mapper';

export type BuySell = 'BUY' | 'SELL';

export type InternalAllocationVolumeAndSellDirection = {
  volume: number | null;
  edfBuysOrSells: BuySell;
};

type NopType = 'Long' | 'Short' | 'Mixed' | 'Zero';

const getOverallNopType = (nop: number[]): NopType => {
  const all = nop.length;
  const positive = nop.filter((value) => value > 0).length;
  const negative = nop.filter((value) => value < 0).length;
  const zero = nop.filter((value) => value === 0).length;

  if (zero > 0) {
    return 'Zero';
  }
  if (positive === all) {
    return 'Long';
  }
  if (negative === all) {
    return 'Short';
  }

  return 'Mixed';
};

const getLowestAbsoluteValue = (values: number[]): number =>
  Math.min(...values.map((value) => Math.abs(value)));

export default (
  horizon: NopHorizon,
  product: Product,
  counterparty: InternalTransferCounterparty = 'WBB',
): InternalAllocationVolumeAndSellDirection => {
  const mapCounterpartyToNopDataKey = {
    WBB: 'wbb',
    Batteries: 'batteries',
    Gas_Peaker: 'gasPeaker',
  } as Record<InternalTransferCounterparty, keyof NopData>;
  const nopData = getNopDataForProduct(horizon, product);
  const counterpartyNopDataKey = mapCounterpartyToNopDataKey[counterparty];
  const nopTypes = `${getOverallNopType(nopData.edf)}/${getOverallNopType(
    nopData[counterpartyNopDataKey],
  )}`;

  const lowestAbsolutes = {
    edfAndCounterparty: getLowestAbsoluteValue([
      ...nopData.edf,
      ...nopData[counterpartyNopDataKey],
    ]),
    justCounterparty: getLowestAbsoluteValue(nopData[counterpartyNopDataKey]),
  };

  const returnMatrixWbb: {
    [key: string]: InternalAllocationVolumeAndSellDirection;
  } = {
    'Long/Mixed': { volume: null, edfBuysOrSells: 'SELL' },
    'Short/Mixed': { volume: null, edfBuysOrSells: 'BUY' },
    'Mixed/Short': { volume: null, edfBuysOrSells: 'SELL' },
    'Mixed/Long': { volume: null, edfBuysOrSells: 'BUY' },
    'Mixed/Mixed': { volume: null, edfBuysOrSells: 'BUY' },

    'Long/Zero': { volume: 0, edfBuysOrSells: 'SELL' },
    'Short/Zero': { volume: 0, edfBuysOrSells: 'BUY' },
    'Zero/Short': {
      volume: lowestAbsolutes.justCounterparty,
      edfBuysOrSells: 'SELL',
    },
    'Zero/Long': {
      volume: lowestAbsolutes.justCounterparty,
      edfBuysOrSells: 'BUY',
    },
    'Zero/Zero': { volume: 0, edfBuysOrSells: 'BUY' },

    'Long/Short': {
      volume: lowestAbsolutes.edfAndCounterparty,
      edfBuysOrSells: 'SELL',
    },
    'Short/Long': {
      volume: lowestAbsolutes.edfAndCounterparty,
      edfBuysOrSells: 'BUY',
    },
    'Short/Short': {
      volume: lowestAbsolutes.justCounterparty,
      edfBuysOrSells: 'SELL',
    },
    'Long/Long': {
      volume: lowestAbsolutes.justCounterparty,
      edfBuysOrSells: 'BUY',
    },
  };

  const returnMatrixNonWbb: {
    [key: string]: InternalAllocationVolumeAndSellDirection;
  } = {
    'Long/Mixed': { volume: null, edfBuysOrSells: 'SELL' },
    'Short/Mixed': { volume: null, edfBuysOrSells: 'BUY' },
    'Mixed/Short': { volume: null, edfBuysOrSells: 'SELL' },
    'Mixed/Long': { volume: null, edfBuysOrSells: 'BUY' },
    'Mixed/Mixed': { volume: null, edfBuysOrSells: 'BUY' },

    'Long/Zero': { volume: 0, edfBuysOrSells: 'SELL' },
    'Short/Zero': { volume: 0, edfBuysOrSells: 'BUY' },
    'Zero/Short': {
      volume: lowestAbsolutes.justCounterparty,
      edfBuysOrSells: 'SELL',
    },
    'Zero/Long': {
      volume: lowestAbsolutes.justCounterparty,
      edfBuysOrSells: 'BUY',
    },
    'Zero/Zero': { volume: 0, edfBuysOrSells: 'BUY' },

    'Long/Short': {
      volume: lowestAbsolutes.justCounterparty,
      edfBuysOrSells: 'SELL',
    },
    'Short/Long': {
      volume: lowestAbsolutes.justCounterparty,
      edfBuysOrSells: 'BUY',
    },
    'Short/Short': {
      volume: lowestAbsolutes.justCounterparty,
      edfBuysOrSells: 'SELL',
    },
    'Long/Long': {
      volume: lowestAbsolutes.justCounterparty,
      edfBuysOrSells: 'BUY',
    },
  };
  // if product is a hh-product, then use custom logic for non-WBB ctpties
  // otherwise use WBB logic
  const halfHoursWithinProduct =
    productMapper().getHalfHourProductsContainedWithin(product);

  const rtn =
    counterparty === 'WBB' || halfHoursWithinProduct.length > 1
      ? returnMatrixWbb[nopTypes] ?? { volume: null, edfBuysOrSells: 'BUY' }
      : returnMatrixNonWbb[nopTypes] ?? { volume: null, edfBuysOrSells: 'BUY' };
  return rtn;
};
