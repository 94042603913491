import { Action } from '../actions';
import {
  IndividualTradingAllowedState,
  TradingAllowedState,
  TradingAllowedTriggerType,
} from './reducer';

export const UPDATE_TRADING_ALLOWED_STATE = 'UPDATE_TRADING_ALLOWED_STATE';
export const UPDATE_INDIVIDUAL_TRADING_ALLOWED_STATE =
  'UPDATE_INDIVIDUAL_TRADING_ALLOWED_STATE';
export const TOGGLE_MANUAL_TRADING_ALLOWED_STATE =
  'TOGGLE_MANUAL_TRADING_ALLOWED_STATE';
export type UpdateIndividualTradingAllowedStatePayload = {
  state: IndividualTradingAllowedState;
  triggerType: TradingAllowedTriggerType;
};

export const updateTradingAllowedState = (
  payload: TradingAllowedState,
): Action => ({ type: UPDATE_TRADING_ALLOWED_STATE, payload });

export const toggleManualTradingAllowedState = (): Action => ({
  type: TOGGLE_MANUAL_TRADING_ALLOWED_STATE,
  payload: null,
});

export const updateIndividualTradingAllowedState = (
  payload: UpdateIndividualTradingAllowedStatePayload,
): Action => ({ type: UPDATE_INDIVIDUAL_TRADING_ALLOWED_STATE, payload });
