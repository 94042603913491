import { allocationSubmissionsQuery } from '../../graphql/queries';
import { Action } from '../actions';
import { apiAction } from '../api/actions';
import {
  AllocationSubmissionsApiResponse,
  SetAllocationSubmissionAsPendingPayload,
  UpdateAllocationSubmissionPayload,
} from './reducer';

export const UPDATE_ALLOCATION_SUBMISSIONS = 'UPDATE_ALLOCATION_SUBMISSIONS';
export const UPDATE_STATUS_OF_ALLOCATION_SUBMISSION =
  'UPDATE_STATUS_OF_ALLOCATION_SUBMISSION';
export const SET_ALLOCATION_RESUBMISSION_AS_PENDING =
  'SET_ALLOCATION_RESUBMISSION_AS_PENDING';
export const ALLOCATION_SUBMISSIONS_ERROR = 'ALLOCATION_SUBMISSIONS_ERROR';
export const SET_ALLOCATION_SUBMISSIONS_AS_LOADING =
  'SET_ALLOCATION_SUBMISSIONS_AS_LOADING';

export const updateAllocationSubmissions = (
  payload: AllocationSubmissionsApiResponse,
): Action => ({
  type: UPDATE_ALLOCATION_SUBMISSIONS,
  payload,
});

export const updateStatusOfAllocationSubmission = (
  payload: UpdateAllocationSubmissionPayload,
): Action => ({
  type: UPDATE_STATUS_OF_ALLOCATION_SUBMISSION,
  payload,
});

export const setAllocationSubmissionAsPending = (
  payload: SetAllocationSubmissionAsPendingPayload,
): Action => ({
  type: SET_ALLOCATION_RESUBMISSION_AS_PENDING,
  payload,
});

export const allocationSubmissionsError = (payload: string) => ({
  type: ALLOCATION_SUBMISSIONS_ERROR,
  payload,
});

export const setAllocationSubmissionsAsLoading = () => ({
  type: SET_ALLOCATION_SUBMISSIONS_AS_LOADING,
  payload: null,
});

export const queryAllocationSubmissions = (): Action => {
  const action = apiAction<AllocationSubmissionsApiResponse>({
    query: allocationSubmissionsQuery,
    onSuccess: (res) => updateAllocationSubmissions(res),
    onFailure: (x) =>
      allocationSubmissionsError(`Zapi submissions retrieval error: ${x}`),
    label: UPDATE_ALLOCATION_SUBMISSIONS,
  });

  return action;
};
