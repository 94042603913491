import React from 'react';
import { Wallace as WallaceWrapper } from '@edfenergy/shift-desk-wallace';
import { FilterContainer, FilterList, Form, ShowHide } from './style';
import { rowsToKeepSelected } from './NopTable';

const nopTableFilter = (
  items: any,
  checkboxOnClick: (e: any) => any,
): JSX.Element => {
  const [isVisible, setIsVisible] = React.useState<boolean>(false);

  const showHideOnClick = () => setIsVisible(!isVisible);

  let isFilterApplied = false;
  for (let i = 0; i < items.length; i += 1) {
    if (items[i].value === false) {
      isFilterApplied = true;
    }
  }

  const filteredItems = items.filter(
    (item: any) => !rowsToKeepSelected.includes(item.name),
  );

  return (
    <WallaceWrapper>
      <div id="nop-table-filter" data-testid="nop-table-filter">
        <ShowHide
          isFilterApplied={isFilterApplied}
          data-testid="show_hide_filter"
          className="material-icons md-18"
          onClick={showHideOnClick}
        >
          visibility
        </ShowHide>
        {isVisible && (
          <Form>
            <FilterContainer>
              {filteredItems.map((item: any, index: number) => {
                const { name, value } = item;
                const isMultiSelector =
                  name === 'Forecasts' || name === 'Indices' || name === 'All';
                const isFirstItem = index === 0;
                return (
                  <FilterList
                    key={name}
                    isMultiSelector={isMultiSelector}
                    isFirstItem={isFirstItem}
                  >
                    <label htmlFor={name}>
                      <input
                        data-testid={`${name}_checkbox`}
                        name={name}
                        id={name}
                        type="checkbox"
                        checked={value}
                        onChange={checkboxOnClick}
                      />
                      {name}
                    </label>
                  </FilterList>
                );
              })}
            </FilterContainer>
          </Form>
        )}
      </div>
    </WallaceWrapper>
  );
};

export default nopTableFilter;
