import { combineReducers } from 'redux';

import { netOpenPosition } from './nop/reducer';
import { currentTime } from './timing/reducer';
import { gateClosures } from './gateClosures/reducer';
import { chart } from './charting/reducer';
import { heartBeat } from './heartbeat/reducer';
import { tradeAllocation } from './tradeAllocation/reducer';
import { user } from './user/reducer';
import { privateTrades } from './privateTrades/reducer';
import { reduceWapState } from './waps/reducer';
import tradingAllowedState from './tradingAllowedState/reducer';
import allocationSubmissions from './allocationSubmissions/reducer';

const rootReducer = () =>
  combineReducers({
    netOpenPosition,
    currentTime,
    gateClosures,
    chart,
    heartBeat,
    tradeAllocation,
    user,
    privateTrades,
    wapState: reduceWapState,
    tradingAllowedState,
    allocationSubmissionState: allocationSubmissions,
  });

export default rootReducer;
