import { AllocationType } from '../../subscriptions/zapiResponseQueueSubscription';
import { Action } from '../actions';
import {
  SET_ALLOCATION_SUBMISSIONS_AS_LOADING,
  UPDATE_ALLOCATION_SUBMISSIONS,
  ALLOCATION_SUBMISSIONS_ERROR,
  UPDATE_STATUS_OF_ALLOCATION_SUBMISSION,
  SET_ALLOCATION_RESUBMISSION_AS_PENDING,
} from './actions';

export type AllocationSubmissionsApiResponse = {
  getAllocationSubmissions: AllocationSubmission[];
};

export type AllocationSubmissionsState = {
  allocationSubmissions: AllocationSubmission[];
  isLoading: boolean;
  hasError: boolean;
  errorMessage?: string;
};

export type AllocationSubmissionStatus =
  | 'UNALLOCATED'
  | 'PENDING'
  | 'FAILED'
  | 'SUCCESS';

export type AllocationSubmission = {
  allocationSubmissionId: string;
  xstring: string;
  traderId: string;
  application: string;
  type: AllocationType;
  tradeId: string;
  counterparty: string;
  buyOrSell: string;
  volume: number;
  price: number;
  status: AllocationSubmissionStatus;
  productName: string;
  createdAt: string;
  updatedAt: string;
  canResubmit: boolean;
  hasResubmitted: boolean;
};

export type UpdateAllocationSubmissionPayload = {
  allocationSubmissionId: string;
  status: AllocationSubmissionStatus;
};

export type SetAllocationSubmissionAsPendingPayload = {
  allocationSubmissionId: string;
};

const updateAllocationSubmissionById = (
  state: AllocationSubmissionsState,
  id: string,
  newData: Partial<AllocationSubmission>,
) => ({
  ...state,
  allocationSubmissions: state.allocationSubmissions.map(
    (allocationSubmission) => {
      if (allocationSubmission.allocationSubmissionId !== id) {
        return allocationSubmission;
      }
      return {
        ...allocationSubmission,
        ...newData,
      };
    },
  ),
});

export const initialAllocationSubmissionsState: AllocationSubmissionsState = {
  allocationSubmissions: [],
  isLoading: false,
  hasError: false,
};

export const allocationSubmissions = (
  state: AllocationSubmissionsState = initialAllocationSubmissionsState,
  action: Action,
): AllocationSubmissionsState => {
  switch (action.type) {
    case UPDATE_ALLOCATION_SUBMISSIONS: {
      const { payload }: { payload: AllocationSubmissionsApiResponse } = action;
      return {
        allocationSubmissions: payload.getAllocationSubmissions ?? [],
        isLoading: false,
        hasError: false,
      };
    }
    case UPDATE_STATUS_OF_ALLOCATION_SUBMISSION: {
      if (state.isLoading) {
        return state;
      }
      const { payload }: { payload: UpdateAllocationSubmissionPayload } =
        action;
      return updateAllocationSubmissionById(
        state,
        payload.allocationSubmissionId,
        { status: payload.status, hasResubmitted: false },
      );
    }
    case SET_ALLOCATION_RESUBMISSION_AS_PENDING: {
      if (state.isLoading) {
        return state;
      }
      const { payload }: { payload: SetAllocationSubmissionAsPendingPayload } =
        action;
      return updateAllocationSubmissionById(
        state,
        payload.allocationSubmissionId,
        { status: 'PENDING', hasResubmitted: true },
      );
    }
    case SET_ALLOCATION_SUBMISSIONS_AS_LOADING: {
      return {
        allocationSubmissions: [],
        isLoading: true,
        hasError: false,
      };
    }
    case ALLOCATION_SUBMISSIONS_ERROR: {
      const { payload }: { payload: string } = action;
      return {
        allocationSubmissions: [],
        isLoading: false,
        hasError: true,
        errorMessage: payload,
      };
    }
    default:
      return state;
  }
};

export default allocationSubmissions;
