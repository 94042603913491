import React from 'react';
import AWSAppSyncClient from 'aws-appsync';
import { toast } from 'react-toastify';
import { Toast } from '@edfenergy/shift-desk-wallace';
import { SubscriptionInterface } from './subscription';
import { zapiResponseWasCreatedSubscription } from '../graphql/subscriptions';
import {
  setPrivateTradeStatusAsAllocationFailed,
  setPrivateTradeStatusAsSuccessfullyAllocated,
} from '../store/privateTrades/actions';
import { NopStore } from '../store';
import { updateStatusOfAllocationSubmission } from '../store/allocationSubmissions/actions';

export type ZapiResponseStatus = 'FAILED' | 'SUCCESS';
export type Application = 'POWER' | 'GAS';
export type AllocationType = 'INTERNAL' | 'XCHANGE' | 'OTC';

type ZapiResponseSubscriptionPayload = {
  data: {
    zapiResponseWasCreated: ZapiResponse;
  };
};

export type ZapiResponse = {
  allocationSubmissionId: string;
  status: ZapiResponseStatus;
  tradeId?: string;
  type: AllocationType;
  application: Application;
};

const messageForToast = (zapiResponse: ZapiResponse): string => {
  const gasPrefix = zapiResponse.application === 'GAS' ? 'GAS: ' : '';
  if (
    zapiResponse.status === 'SUCCESS' &&
    zapiResponse.type === 'XCHANGE' &&
    zapiResponse.tradeId !== null
  ) {
    return `${gasPrefix}Trade allocation successful for trade ID: ${zapiResponse.tradeId}`;
  }
  if (
    zapiResponse.status === 'FAILED' &&
    zapiResponse.type === 'XCHANGE' &&
    zapiResponse.tradeId !== null
  ) {
    return `${gasPrefix}Trade allocation failed for trade ID: ${zapiResponse.tradeId}`;
  }
  if (zapiResponse.status === 'SUCCESS' && zapiResponse.type === 'INTERNAL') {
    return `${gasPrefix}Internal transfer successful`;
  }
  if (zapiResponse.status === 'FAILED' && zapiResponse.type === 'INTERNAL') {
    return `${gasPrefix}Internal transfer failed`;
  }
  if (zapiResponse.status === 'SUCCESS' && zapiResponse.type === 'OTC') {
    return `${gasPrefix}OTC transfer successful`;
  }
  if (zapiResponse.status === 'FAILED' && zapiResponse.type === 'OTC') {
    return `${gasPrefix}OTC transfer failed`;
  }
  return '';
};

class ZapiResponseQueueSubscription implements SubscriptionInterface {
  private observable: any;

  private subscription: any;

  private store: NopStore;

  constructor(client: AWSAppSyncClient<any>, store: NopStore) {
    this.store = store;
    this.observable = client.subscribe({
      query: zapiResponseWasCreatedSubscription,
    });
  }

  public handleZapiResponse(zapiResponse: ZapiResponse) {
    const autoClose = zapiResponse.status !== 'FAILED' ? 5000 : false;

    const message = messageForToast(zapiResponse);
    if (message.length > 0) {
      toast(
        <Toast
          id="zapi-response-toast"
          messageType={zapiResponse.status === 'FAILED' ? 'error' : 'success'}
        >
          {message}
        </Toast>,
        {
          autoClose,
          closeButton: false,
          position: toast.POSITION.TOP_CENTER,
        },
      );
    }

    this.store.dispatch(
      updateStatusOfAllocationSubmission({
        allocationSubmissionId: zapiResponse.allocationSubmissionId,
        status: zapiResponse.status,
      }),
    );

    if (zapiResponse.application !== 'POWER') {
      return;
    }

    if (zapiResponse.type !== 'XCHANGE' || !zapiResponse.tradeId) {
      return;
    }

    if (zapiResponse.status === 'SUCCESS') {
      this.store.dispatch(
        setPrivateTradeStatusAsSuccessfullyAllocated({
          tradeId: zapiResponse.tradeId,
        }),
      );
    }
    if (zapiResponse.status === 'FAILED') {
      this.store.dispatch(
        setPrivateTradeStatusAsAllocationFailed({
          tradeId: zapiResponse.tradeId,
        }),
      );
    }
  }

  start(): void {
    this.subscription = this.observable.subscribe({
      next: (response: ZapiResponseSubscriptionPayload) => {
        const zapiResponse = response.data.zapiResponseWasCreated;
        this.handleZapiResponse(zapiResponse);
      },
      error: (error: any) => {
        console.log(
          `Error ocurred in subscription: ZapiResponseQueueSubscription`,
          error,
        );
        this.restart();
      },
    });
  }

  stop(): void {
    this.subscription.unsubscribe();
  }

  restart(): void {
    this.stop();
    this.start();
  }
}

export default ZapiResponseQueueSubscription;
