const displayNames: Record<string, Record<string, string>> = {
  EDF: {
    Nuclear: 'Nuclear',
    Coal: 'Coal',
    Batteries: 'Batteries',
    Gas_Peaker: 'Gas Peaker',
    Embed_Gen: 'Embed_Gen',
    Customer_Vol: 'Customer_Vol',
    Trades: 'Trades',
  },
  WBB: {
    Gas: 'WBB',
    Trades: 'WBB Trades',
  },
  Indices: {
    Hourly_Auction_EPEX: 'DA Hourly Auction - EPEX',
    Hourly_Auction_N2EX: 'DA Hourly Auction - N2EX',
    HH_Auction_EPEX: 'DA HH Auction - EPEX',
    HH_Auction_N2EX: 'DA HH Auction - N2EX',
    MIP_EPEX: 'MIP - EPEX',
    MIP_N2EX: 'MIP - N2EX',
    WAP_EPEX: 'WAP - EPEX',
    WAP_N2EX: 'WAP - N2EX',
    CASH_OUT: 'Cash Out',
    IMRP: 'IMRP',
  },
};

export default displayNames;
